/* PISA ESTILOS DE LA LIBRERIA DE ZOOM */
.react-transform-wrapper {
  height: 100vh !important;
  width: 100% !important;
  cursor: grab;
}
.react-transform-wrapper:active {
  cursor: grabbing;
}
.react-transform-component {
  justify-content: center !important;
  align-items: center !important;
  /* height: 45% */
}
.react-transform-component {
  margin-top: -2rem !important;
}

@media (max-width: 1200px) {
  .header_org_container {
    position: inherit !important;
  }
}

@media (max-width: 1800px) {
  .react-transform-component {
    width: fit-content !important;
  }
}

#div-ref {
  display: flex;
}
