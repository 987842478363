.header_drawer {
  height: 110px;
  background-color: var(--blue-lis);
  position: relative;
  display: flex;
  justify-content: center;
  padding: 10px !important;
}

div.icons > button:nth-child(2) > svg > path {
  fill: white !important;
}

.img_drawer {
  width: 100%;
  /* width: 150px; */
  height: 100%;
  /* border-radius: 100%; */
  /* top: 25px; */
  top: 0px;
  position: absolute;
  object-fit: cover;
  border-bottom: 10px solid var(--blue-lis);
}

.container_tags {
  display: flex;
  flex-direction: column;
  padding-bottom: 10px;
}

.drawer_name {
  color: black;
  text-align: center;
  font-size: 25px;
  font-family: var(--lis-body);
  width: 95%;
}

.drawer_job {
  text-align: center;
  font-size: 12px;
  font-family: var(--lis-body);
  color: grey;
}

.info_title_drawer {
  color: black;
  text-align: start;
  font-size: 17px;
  font-family: var(--lis-title);
  text-decoration: underline black;
}

.info_body_drawer {
  cursor: pointer;
  color: black;
  text-align: start;
  font-size: 13px;
  font-family: var(--lis-body);
}
.info_body_drawer:hover {
  color: #092e3b;
}

.info_body_drawer_comite {
  color: black;
  text-align: start;
  font-size: 13px;
  font-family: var(--lis-body);
}
.info_body_drawer_comite:hover {
  color: #092e3b;
}

div.col-12.header_drawer > button > svg > path {
  fill: white;
}

button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium {
  z-index: 10;
}

.header_drawer
  > button.MuiButtonBase-root.MuiIconButton-root.MuiIconButton-sizeMedium
  > svg
  > path {
  fill: #092e3b !important;
}

.MuiTooltip-tooltip.MuiTooltip-tooltipPlacementTop {
  background-color: #fff !important;
  font-size: 11px !important;
  color: rgba(0, 0, 0, 0.87);
  box-shadow: 1px 1px rgba(0, 0, 0, 0.2);
  border: 0.1px solid rgba(9, 46, 59, 0.1);
}
