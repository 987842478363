.positions-container{
    display: flex;
    flex-direction: column;
    gap: 5px;
    width: 100%;
    max-height: 90%;
    overflow-y: scroll;
}

.position{
    background-color: white;
    gap: 5px;
    padding: 5px;
    font-size: 18px;
    width: 90%;
    display: flex;
    align-items: center;
    padding-left: 15px;
}

.positions-tabs{
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-content: flex-start;
    
}

.position_tab{
    display: flex;
    gap: 20px;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
}

.save_button {
    background-color: var(--blue-lis) !important;
    font-family: var(--lis-title) !important;
    font-size: 1rem !important;
    color: white !important;
    padding: 0.5rem 1rem !important;
    width: 100%;
}

.new-position-input{
  max-height: 40px;
}

.save_button_two{
    background-color: var(--blue-lis) !important;
    font-family: var(--lis-title) !important;
    font-size: 1rem !important;
    color: white !important;
    padding: 0.5rem 1rem !important;
    width: 20%;
}

.position_icons{
    display: flex;
    align-items: center;
    gap: 10px;
}

.icons_container{
    display: flex;
    gap: 5px;
}

.icon{
    cursor: pointer;
}

.modal_body{
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
}

.modal_body_span{
    text-align: center;
    width: 80%;
}

.modal_body_buttons{
    display: flex;
    gap:10px;

}

.modal_button{
    background-color: var(--blue-lis);
    color: white;
    font-weight: 400;
    border-radius: 3px;
    padding: 5px 10px;
    width: 200px;
    border: none;

}

#cancel{
    background-color: lightgrey;
    color: black;
    border: none;
    font-weight: bold;
}

.separador{
    height: 100%;
    width: 1px;
    border: 1px solid var(--blue-lis);
}

.tab_colum{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    height: 100%;
    margin-top: 30px;
}

.colum_title{
    font-weight: bold;
    font-size: larger;
}

ul {
    list-style-type: none;
    padding: 0;
}

li {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

/* Aumentar el tamaño de los checkboxes */
.modal_body input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.list-button{
    width: 100%;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
}

.delete-icon {
    width: 300px;
    display: flex;
    justify-content: end;
    text-decoration: underline;
    font-weight: lighter;
    color: black;
    cursor: pointer;
}

.checkbox_all{
    font-weight: bold;
    text-decoration: underline;
}

.checkbox_all_div{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: start;
    gap: 5px;
}

.employees_list{
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 5px;
    align-items: start;
    justify-content: start;
    padding: 0;
    max-height: 45vh;
    overflow-y: scroll;
}

.all_delete{
    width: 70%;
    display: flex;
    justify-content: space-between;
}