.modalHeader {
  background-color: var(--blue-lis);
  color: white;
  border-radius: 0px !important;
  display: flex;
  justify-content: center;
}
.modal-content {
  border-radius: 0px !important;
  border: 0px !important;
}
.modalHeader > .MuiButtonBase-root > svg {
  fill: white !important;
}

.modal-title {
  font-family: var(--lis-title);
  text-align: center !important;
  width: 100% !important;
}
.modal-body {
  background-color: #e9e9e9;
  display: flex;
  flex-direction: column;
  align-items: center;
}
