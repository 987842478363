.save_button {
    background-color: var(--blue-lis) !important;
    font-family: var(--lis-title) !important;
    font-size: 1rem !important;
    color: white !important;
    padding: 0.5rem 1rem !important;
    margin: 1rem auto 0 !important;
    width: fit-content;

  }

#draft {
    text-align: left;
    text-transform: capitalize;
}