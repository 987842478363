.cardsContainers {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 90%;
    min-height: 50px;
    border-bottom: 1px solid var(--blue-lis);
}


.card_name{
    width: 100%;
    font-size: 1rem;
    
}
.card_rol{
    font-size: 1rem;
    width: 100%;
}