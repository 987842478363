.org_Button {
  background-color: var(--blue-lis) !important;
  font-family: var(--lis-title) !important;
  font-size: 10px !important;
  color: white !important;
  margin-right: 10px !important;
  padding: 8px !important;
  font-weight: bold !important;
}

.orgchart-container > .oc-mask .spinner::before {
  width: 100px;
  height: 100px;
  border-width: 10px;
  border-radius: 50px;
  border-top-color: var(--blue-lis) !important;
  border-bottom-color: var(--blue-lis) !important;
  border-left-color: var(--blue-lis) !important;
}
