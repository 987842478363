.save_button {
    background-color: var(--blue-lis) !important;
    font-family: var(--lis-title) !important;
    font-size: 0.8rem !important;
    color: white !important;
    padding: 0.5rem 1rem !important;
    margin: 1rem auto 0 !important;
    width: fit-content;
}

.person-box_img {
  width: 5rem;
  height: 5rem;
  border-radius: 3rem;
  object-fit: cover;
  margin-right: -4rem;
  z-index: 1;
  border: 1px #8080807a solid;
}

.whiteBox {
  background-color: white;
  margin-left: 2rem;
  padding: 1rem 3rem;
}

.person-box_title {
  font-weight: 800;
  font-family: var(--lis-title);
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 1.8rem;
}

.person-box_subtitle {
  font-size: 1.3rem;
  color: #808080;
}


#manager, #layer {
  text-align: left;
  /* text-transform: capitalize; */
}

.css-u4tvz2-MuiFormLabel-root.Mui-focused {
  color: var(--blue-lis) !important;
}
