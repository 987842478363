.save_button {
  background-color: var(--blue-lis) !important;
  font-family: var(--lis-title) !important;
  font-size: 1rem !important;
  color: white !important;
  padding: 0.5rem 1rem !important;
  margin: 1rem auto 0 !important;
  width: fit-content;
  max-width: 300px;
}

.button-container {
  text-align: left;
  margin-right: 2rem;
}

#company,
#delegation,
#tags {
  text-align: left;
  text-transform: capitalize;
}
