.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

.main-title {
  font-family: var(--lis-title);
  text-align: center;
  font-weight: bold;
  padding: 1px;
  text-transform: uppercase;
  font-size: 1.8rem;
  margin: 1rem 0;
}

.date {
  display: inline-block;
  margin: 0;
}
#tree {
  height: calc(100vh - 100px) !important;
}

#tree[data-is-draft="true"] {
  height: calc(100vh - 160px) !important;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

:root {
  --primary-color: white;
  --secondary-color: #0088a6;
  --blue-lis: #092e3b;
  --lis-ligthblue: #00b1e2;
  --aedas-green: #00b176;
  --toastify-color-success: #00b176 !important;
  --toastify-color-error: #b1003b !important;
  --aedas-red: #b1003b;
  --font-pay: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  --lis-title: "lis-title";
  --lis-body: "lis-body";
  --iconColor: black;
  --iconButtonSelected: #0088a6;
}

body {
  font-family: var(--lis-title), var(--lis-body);
  background-color: #e9e9e9 !important;
  height: 100%;
  -webkit-text-size-adjust: none !important;
}
@font-face {
  font-family: "lis-body";
  src: url(/public/Assets/Fonts/Heebo-VariableFont_wght.ttf);
}

@font-face {
  font-family: "lis-title";
  src: url(/public/Assets/Fonts/WorkSans-Medium.ttf);
}

.row {
  margin: 0px !important;
  overflow: hidden;
  padding: 0;
}

.row > * {
  padding: 0 !important;
}

/* main > div {
  height: 100%;
  width: 100%;
} */

.react-tabs__tab {
  background-color: #092e3bad;
  color: white;
  font-family: var(--lis-title);
  text-align: center;
  border-radius: 0px !important;
  border-color: auto !important;
  width: 200px;
  margin-right: 20px;
}

@media (max-width: 768px) {
  .react-tabs__tab {
    width: 100%;
    margin-right: 0;
  }
}

.react-tabs__tab--selected {
  background-color: var(--blue-lis) !important;
  color: white !important;
  font-family: var(--lis-title) !important;
  border-radius: 0px !important;
  border-color: none !important;
  text-align: center;
  width: 200px;
  margin-right: 20px;
}
.MuiDataGrid-root {
  font-family: var(--lis-title);
}
.MuiDataGrid-columnHeaderTitle {
  font-weight: bold !important;
}

.react-tabs {
  height: 100%;
}

.react-tabs__tab-panel {
  height: 100%;
}
.modal-backdrop.show {
  z-index: 1201;
}
.modal {
  z-index: 1201 !important;
}
.Toastify__progress-bar--success {
  background: var(--aedas-green) !important;
}
.MuiDataGrid-columnHeaderTitleContainer {
  justify-content: center;
}

.MuiDataGrid-columnHeaders {
  background-color: #092e3b;
  color: white;
}
.MuiDataGrid-columnHeader > .MuiDataGrid-columnSeparator > .MuiSvgIcon-root {
  fill: white !important;
}
.MuiDataGrid-columnHeaderDraggableContainer
  > .MuiDataGrid-menuIcon
  > .MuiButtonBase-root
  > .MuiSvgIcon-root {
  fill: white !important;
}

.MuiDataGrid-cell {
  justify-content: center !important;
}

.MuiDataGrid-iconButtonContainer > .MuiButtonBase-root > .MuiSvgIcon-root {
  fill: white !important;
}

.MuiDataGrid-columnHeaderTitleContainerContent
  > .MuiCheckbox-root
  > .MuiSvgIcon-root {
  fill: white !important;
}
.css-1pans1z-MuiDataGrid-virtualScroller::-webkit-scrollbar {
  width: 5px;
  background: var(--aedas-lightgrey);
}

.css-1pans1z-MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb {
  background: var(--aedas-grey);
}

.MuiDataGrid-footerContainer
  .MuiTablePagination-root.css-rtrcn9-MuiTablePagination-root
  p {
  margin-bottom: 0;
}

h3 {
  display: block !important;
  font-size: 1.17em !important;
  font-weight: bold !important;
}

h5 {
  display: block !important;
  font-size: 0.83em !important;
  font-weight: bold !important;
}

.ButtonAedas {
  background-color: var(--blue-lis) !important;
  color: white !important;
}

/* .ui-provider {
  height: 100vh;
} */

.transform-component-module_wrapper__1_Fgj {
  position: relative;
  width: -moz-fit-content;
  width: 100%;
  height: -moz-fit-content;
  height: fit-content;
  overflow: hidden;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin: 0;
  padding: 0;
}

.dataGrid-table {
  height: 100%;
  background: white;
}
.dataGrid-table--employees {
  height: 100%;
  background: white;
}

@media screen and (max-width: 1680px) {
  .dataGrid-table {
    height: 100%;
    background: white;
  }
  .dataGrid-table--employees {
    height: 100%;
    background: white;
  }
}
