.company_box {
  width: 10%;
  height: 80px;
  background-color: white;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  color: var(--blue-lis);
  border: 1px solid #cdcdcdd0;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-width: 140px;
  /* flex: 2; */
}
.text_inner {
  font-size: 13px;
  padding: 4px;
}

.company_box:hover {
  transform: translateY(-2px);
  box-shadow: 0px 0px 20px 4px #cdcdcda4;
}
