.error_container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.error_box {
  padding: 20px;
  border-radius: 20px;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.error_title {
  text-align: center;
  font-size: 40px;
  color: var(--aedas-red);
}

.error_body {
  text-align: center;
  font-size: 20px;
}
