/* Soluciona un pixel que queda suelto por el organigrama */
.orgchart > ul > li > ul li:only-child::before {
  width: 1px;
}
.orgchart > ul > li > ul li:first-child::before {
  left: calc(50% - 0px);
}
/* Lineas horizontales en los organigramas*/
.orgchart.myChart > ul > li > ul li::before {
  border-top: 0.5px solid #092e3b;
  top: -9px;
}
/* Pone las lines verticales, para arriba y abajo si desciende de alguien y le descienden, y solo para abajo si le desciende alguien y no desciende de nadie.
Solo se lo pone a gente con layer 0 o null */
.orgchart.myChart > ul > li > ul li .oc-node::before {
  background-color: #092e3b;
  width: 1px;
  height: 13px;
  top: -10px;
  left: calc(50% - 0px);
}
.orgchart.myChart ul li .oc-node:not(:only-child)::after {
  background-color: #092e3b;
  width: 1px;
  height: 13px;
  left: calc(50% - 0px);
}
/* Elimina el background que le pone por defecto cuando clickas algunas tarjetas */
.orgchart ul li .oc-node.selected {
  background-color: rgba(255, 255, 255, 0) !important;
}
/* Oculta las flechas que trae el organigrama por defecto */
.oc-edge {
  display: none !important;
}

.orgchart.myChart {
  background-color: #e9e9e9 !important;
  cursor: pointer;
}
.orgchart-container {
  margin: 0px;
  border: 0px;
  overflow: hidden !important;
  background-color: red;
  /* Propiedad que hace que se centre el organigrama pero se descentra el otro */
  height: 0%;
}
.orgchart {
  background-image: none !important;
  padding: 0;
}
/* grabber cursor */
.oc-hierarchy {
  cursor: grab;
}
.oc-hierarchy:active {
  cursor: grabbing;
}

.d-flex.justify-content-end.px-4 > button > svg > path {
  fill: white;
}
.max-width {
  max-width: calc(100vw - 70px) !important;
}
/* CLASES EXCLUSIVAS DE LA PARTE DE ORGANIGRAMAS NORMALES */
.orgChart__wrapper {
  max-width: 100%;
  min-height: 80%;
  display: flex;
}
.orgChart__title {
  font-family: var(--lis-title);
  font-size: 20px;
  margin: 0px;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  padding: 1px;
}
.orgChart__icon {
  margin-left: 5px !important;
  background-color: var(--blue-lis) !important;
  fill: white;
}
.dropdown_filter {
  background-color: white;
  border-radius: 10px;
  height: 203px;
  margin: 1rem 2.5rem;
  right: 0px;
  position: absolute;
  width: 244px;
  z-index: 1;
}
.node_box-actionsContainer {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  top: 30px;
  position: absolute;
}
.node_box-titleName {
  font-size: 7px !important;
  margin: 0px !important;
  font-family: var(--lis-body);
  font-weight: 500 !important;
}
.node_box-job {
  font-size: 4px !important;
  margin: 0px !important;
  color: grey;
  font-family: var(--lis-body);
  font-weight: 400 !important;
}
