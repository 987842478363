.logo {
  height: 100px;
}
@media (max-width: 650px) {
  .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root {
    padding: 0px;
  }

  .container_header_search {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px;
    flex-direction: column;
  }

  .backButton {
    background-color: var(--blue-lis) !important;
    position: absolute !important;
    top: 372px !important;
    fill: white !important;
  }
}

@media (max-width: 450px) {
  .backButton {
    background-color: var(--blue-lis) !important;
    position: absolute !important;
    top: 400px !important;
    fill: white !important;
  }
}
.container_search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  flex-direction: column;
  background-color: white;
}

.search_bar {
  width: 80%;
  display: flex;
  justify-content: center;
}

.container_header_search {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px;
  background-color: white;
}

.company_list_container {
  width: 50%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  height: 100%;
  justify-content: center;
}
.company_list {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
.department_list_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.department_list {
  width: 100%;
  height: auto;
  overflow: auto;
  padding: 10px;
  overflow: auto;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-content: flex-start;
}

.companies_list_update {
  /* justify-content: end; */
  margin-bottom: 1rem;
}

@media screen and (max-width: 800) {
  .companies_list_update {
    /* justify-content: end !important; */
  }
}

.companies_list {
  width: 100%;
  height: auto;
  padding: 10px;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}
@media screen and (max-width: 1680px) {
  .department_list {
    width: 100%;
    height: auto;
    overflow: auto;
    padding: 10px;
    overflow: auto;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
  .department_list::-webkit-scrollbar {
    width: 5px;
    background-color: var(--aedas-lightgrey);
    overflow: auto;
  }
  .department_list::-webkit-scrollbar-thumb {
    overflow: auto;
    background-color: var(--aedas-grey);
  }

  .companies_list {
    width: 100%;
    height: auto;
    padding: 10px;
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
  }
}

.employee_list {
  padding: 20px;
  display: flex;
  gap: 20px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
}

.company_department_container {
  display: flex;
  flex-direction: row;
  background-color: white;
}

.backButton {
  background-color: var(--blue-lis) !important;
  position: absolute !important;
  top: 275px;
  fill: white !important;
}

.backButtonBase {
  background-color: var(--blue-lis) !important;
}

#BackButton > svg {
  fill: white !important;
}
.img_wrapper {
  background-color: white;
  height: 500px;
}
.img_background {
  object-fit: fill;
  width: 100%;
  opacity: 0.5;
}

.container_header {
  position: relative;
  overflow: hidden;
  height: 250px;
}

/* .degraded_white_container_header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: white;
  opacity: 0.7;
} */

.header {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.logo {
  width: 50px;
}

.title_header {
  font-size: 20px;
  margin: 0px !important;
  text-align: center;
  cursor: pointer;
}
.title_departments {
  font-size: 20px;
  margin: 0px !important;
  text-align: center;
}
.title_header_app {
  cursor: pointer;
  font-size: 20px;
  margin: 0px !important;
}
.search_container {
  height: 128px;
  width: 541px;
  background-color: white;
  box-shadow: 0px 0px 9px 4px #cdcdcd;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.search_title {
  font-size: 15px;
  margin: 0px !important;
  font-weight: bold;
}

.Mui-focused {
  color: var(--secondary-color) !important;
}
fieldset {
  border-color: var(--secondary-color) !important;
}

.company_container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.company_department {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex-direction: column;
}

.container_list {
  width: 85%;
  background-color: white;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

@media (max-width: 1600px) {
  .container_list {
    width: 95% !important;
    background-color: white;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
  }
  .title_header_app {
    display: none;
    text-align: center;
    width: 100%;
  }

  .search_container {
    height: 128px;
    min-width: 69vw;
    max-width: 35vw;
    background-color: white;
    box-shadow: 0px 0px 9px 4px #cdcdcd;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
}

@media (max-width: 600px) {
  .img_background {
    width: fit-content;
    position: absolute;
    top: -40px;
    height: 80vh;
  }
}

.header__banner {
  display: flex;
  align-items: center;
  justify-content: start;
  width: 100%;
  z-index: 20;
  cursor: pointer;
  height: 80px;
  padding-left: 9px;
}
