.search_box {
  width: 320px;
  height: 110px;
  flex-direction: column;
  background-color: #fff;
  border-top: 4px solid var(--blue-lis);
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  box-shadow: 0px 0px 9px 1px #cdcdcd;
  position: relative;
}
.imgTree_search {
  border-radius: 100%;
  width: 100px !important;
  height: 100px;
  margin-left: 2px;
}

.more_info_container_search {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  gap: 5px;
  width: 216px;
  height: 100%;
}

.info_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 200px;
  height: 100px;
  overflow: hidden;
}

.container_name_search {
  top: 5px;
  padding: 2px;
  width: 100%;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
}

.titleName_search {
  font-size: 15px !important;
  margin: 0px !important;
  font-family: var(--lis-body);
  font-weight: 500 !important;
  text-align: center;
}

.titleName_job_search {
  font-size: 10px !important;
  margin: 0px !important;
  color: grey;
  font-family: var(--lis-body);
  font-weight: 400 !important;
  text-align: center;
}

.container_action_search {
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  /* padding-top: 4px; */
  top: 70px;
  position: absolute;
  gap: 10px;
}

.boxModal {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 10px;
}
